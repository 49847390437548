
// @ is an alias to /src
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import BorderButton from "@/components/atomics/BorderButton.vue";
import { defineComponent } from "vue";
import { ForgotPasswordRepository, RepositoryFactory } from "@/lib/https";
import { useFieldError, useForm, useFormErrors } from "vee-validate";
import router from "@/router";

export default defineComponent({
  name: "ForgotPassword",
  components: {
    ValidationTextField,
    FlatButton,
    BorderButton,
  },
  setup(props) {
    const { requestForgotPasswordCode } = forgotPasswordMethods();

    const goNext = () => {
      router.push({ name: "ForgotPasswordConfirm" });
    };

    const { submit, errors, values, emailError } = formHandler(
      requestForgotPasswordCode,
      goNext
    );

    return {
      requestForgotPasswordCode,
      errors,
      submit,
      values,
      emailError,
    };
  },
  methods: {
    cancelForgotPassword() {
      this.$router.push({ name: "Login" });
    },
  },
});

const formHandler = (
  onSubmit: CallableFunction,
  onSuccess: CallableFunction
) => {
  const { values, errors, submitForm, setFieldError } = useForm();
  const emailError = useFieldError("email");

  const submit = async () => {
    await submitForm().then(async () => {
      if (errors.value["email"]) return;

      const formData = new FormData();
      formData.append("email", values["email"] ?? "");

      try {
        if (await onSubmit(formData)) {
          localStorage.setItem("emailForgotPassword", values["email"]);
          onSuccess();
        }
      } catch (e: any) {
        if (e.data) setFieldError("email", e.data.message);
      }
    });
  };

  return { submit, errors, values, emailError };
};

//Create method from API
const forgotPasswordMethods = () => {
  const { requestForgotPasswordCode } =
    RepositoryFactory.getRepository<ForgotPasswordRepository>(
      ForgotPasswordRepository
    );

  return {
    requestForgotPasswordCode,
  };
};

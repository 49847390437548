import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "forgot-password" }
const _hoisted_2 = { class: "inner login-container" }
const _hoisted_3 = { class: "login-title" }
const _hoisted_4 = { class: "tagline" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "login-body" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "mail-field mt-5" }
const _hoisted_9 = { class: "group-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_BorderButton = _resolveComponent("BorderButton")!
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("login.forgotPassTagline")), 1),
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("login.forgotPassTitle")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", {
          innerHTML: _ctx.$t('login.forgotPassText'),
          class: "forgot-pass-text mt-5"
        }, null, 8, _hoisted_7),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createVNode(_component_ValidationTextField, {
              name: "email",
              rules: "required|email",
              placeholder: _ctx.$t('login.forgotPassEmail'),
              "keep-error-space": false,
              "max-length": 320,
              message: {
                error_required: _ctx.$t('errors.required'),
                error_format_email: _ctx.$t('errors.formatEmail'),
              }
            }, null, 8, ["placeholder", "message"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_BorderButton, {
            text: _ctx.$t('login.forgotPassCancelBtn'),
            class: "forgot-cancel-btn",
            onOnClick: _ctx.cancelForgotPassword
          }, null, 8, ["text", "onOnClick"]),
          _createVNode(_component_FlatButton, {
            enabled: _ctx.values.email != '' && _ctx.emailError == null,
            text: _ctx.$t('login.forgotPassSendBtn'),
            class: "forgot-confirm-btn",
            onClick: _ctx.submit
          }, null, 8, ["enabled", "text", "onClick"])
        ])
      ])
    ])
  ]))
}